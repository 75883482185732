import { createRouter, createWebHistory } from 'vue-router'
// import notFound from '../components/notFound.vue'
import HomepageComponent from '../components/HomepageComponent.vue';

const routes = [
  {
    path: '/',
    component: HomepageComponent
  },
//   {
//     path: '/:pathMatch(.*)*',
//     component: notFound
//   },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
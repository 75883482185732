<template>
    <div class="wrapper" id="products">
      <!-- Start Portfolio -->
      <section class="portfolio">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>Products</h1>
                <h2>Our products</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="sec-content">
                <h2 class="text-center">
                    The first of our products is still in development.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Portfolio -->
    </div>
</template>
<template>
    <div class="wrapper" id="services">
        <!-- Start Services -->
      <section class="services" id="services">
        <div class="container h-100">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>Services</h1>
                <h2>Our Services</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="sec-content">
                <div class="service-container">
                  <div class="row g-4">
                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-code-alt"></i>
                          <h3>Web Development & Design</h3>
                          <p>
                            We craft responsive and dynamic websites tailored to your business needs, utilizing the latest technologies to ensure a seamless 
                            user experience and impactful online presence.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-mobile-alt"></i>
                          <h3>Mobile Development</h3>
                          <p>
                            We create intuitive, high-performance mobile applications for both iOS and Android platforms, 
                            transforming your ideas into powerful apps that stand out in the competitive market.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-laptop"></i>
                          <h3>Coding Bootcamps</h3>
                          <p>
                            Our immersive virtual coding bootcamps equip you with essential tech skills through hands-on training and real-world projects, 
                            led by industry experts.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="service-item">
                        <div class="service-card">
                          <i class="bx bx-bar-chart-alt-2"></i>
                          <h3>Search Engine Optimisation</h3>
                          <p>
                            Our SEO services boost your online visibility with proven strategies that enhance search engine rankings, 
                            driving organic traffic and increasing your reach.
                          </p>
                          <div class="card-border">
                            <span class="top"></span>
                            <span class="right"></span>
                            <span class="bottom"></span>
                            <span class="left"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Services -->
    </div>
</template>
<template>
     <div class="wrapper" id="contact">
      <!-- Start Contact -->
      <section class="contact" id="contact">
        <div class="container h-100">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>Contact</h1>
                <h2>Get In Touch</h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="sec-content">
                <div class="row g-5">
                  <div class="col-lg-7">
                    <div class="contact-form">
                        <form action="#" id="contact-form">
                            <div class="row g-4">
                            <div class="col-lg-6">
                                <div class="form-group">
                                <input type="text" required name="name" placeholder="Name" class="form-control" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                <input type="email" required name="email" placeholder="Email" class="form-control" />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                <input type="text" required name="subject" placeholder="Subject" class="form-control" />
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                <textarea name="message" required rows="7" placeholder="Your Message" class="form-control"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                <span class="form-message" hidden></span>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="form-group">
                                <button type="submit" class="button">
                                    Send Message <i class="bx bxs-paper-plane"></i>
                                </button>
                                </div>
                            </div>
                            </div>
                        </form>
                        </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="detail">
                      <h2>Don't hesitate to reach out</h2>
                      <p>
                        Want to learn more about Makkan Innovation, get a quote, or speak with an expert? Let us know what you are looking for and we'll get back to you right away.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Contact -->
    </div>
</template>
<template>
    <div class="wrapper" id="about">
      <!-- Start About -->
      <section class="about">
        <div class="container h-100">
          <div class="row">
            <div class="col-12">
              <div class="sec-title">
                <h1>About</h1>
                <h2>Who are we?</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row g-5">
                <div class="col-lg-4">
                  <div class="sec-image">
                    <img src="assets/img/Segun-modified.jpg" alt="About" class="img-fluid about-img" />
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="sec-content mt-5">
                    <p>
                      Makkan Innovation is a pioneering remote-first software agency, delivering cutting-edge solutions in web and 
                      mobile app development, while also offering comprehensive coding bootcamps, and SEO services. Our commitment to 
                      innovation extends to developing transformative products designed to empower business owners ensuring they thrive
                      in the digital landscape.
                    </p>
                    <p>
                      Our Vision at Makkan Innovations is to lead digital innovation by providing transformative solutions that empower businesses
                      globally, driven by relentless innovation, integrity and dedication to excellence. 
                    </p>
                    <p>
                      - Joe-Alabi Olusegun <br>
                      <i>Lead Software Developer</i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End About -->
    </div>
</template>
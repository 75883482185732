<template>
    <div class="wrapper" id="testimonials">

        <!-- Start Testimonial -->
        <section class="testimonial" id="testimonial">
            <div class="container h-100">
                <div class="row">
                    <div class="col-12">
                        <div class="sec-title">
                        <h1>Reviews</h1>
                        <h2>What our clients say</h2>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="sec-content">
                        <div class="testimonial-slider owl-carousel owl-theme">

                            <div class="testimonial-item">
                            <div class="testimonial-card">
                                <div class="d-flex justify-content-between">
                                <div class="profile">
                                    <div class="image">
                                    <img src="assets/img/testimonial/ogunyomi21.jpg" alt="Testimonial" />
                                    </div>
                                    <div class="text">
                                    <h3>Ogunyomi Ayomide Samuel </h3>
                                    <h5>CEO, Perissos Creation</h5>
                                    </div>
                                </div>
                                <i class="bx bxs-quote-right"></i>
                                </div>
                                <p>
                                    Working with Makkan Innovation has been an incredible experience. I am continually impressed by the innovation and creativity put into their work. The attention to detail and commitment to excellence truly set them apart. I highly recommend Makkan Innovation to anyone looking for top-notch quality and unique solutions.
                                </p>
                            </div>
                            </div>

                            <div class="testimonial-item">
                            <div class="testimonial-card">
                                <div class="d-flex justify-content-between">
                                <div class="profile">
                                    <div class="image">
                                    <img src="assets/img/testimonial/ajari.jpg" alt="Testimonial" />
                                    </div>
                                    <div class="text">
                                    <h3>Emmanuel Ajari</h3>
                                    <h5>CTO, Pysis Technologies</h5> 
                                    </div>
                                </div>
                                <i class="bx bxs-quote-right"></i>
                                </div>
                                <p>
                                   Outstanding team of developers. Their expertise in software development and ability to solve complex problems quickly set them apart.
                                </p>
                            </div>
                            </div>

                            
                            <div class="testimonial-item">
                            <div class="testimonial-card">
                                <div class="d-flex justify-content-between">
                                <div class="profile">
                                    <div class="image">
                                    <img src="assets/img/testimonial/oztech.jpg" alt="Testimonial" />
                                    </div>
                                    <div class="text">
                                    <h3>Toyosi</h3>
                                    <h5>CEO, oztech.com.ng</h5>
                                    </div>
                                </div>
                                <i class="bx bxs-quote-right"></i>
                                </div>
                                <p>
                                Makkan Innovation is a reliable brand that helped develop my web application and have provided web support for it in the last 2 years.
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Testimonial -->

         <!-- Start Counters -->
        <section class="counters">
          <div class="container">
              <div class="row">
                  <div class="col-12">
                      <div class="sec-content">
                          <div class="row g-4">
                              <div class="col-md-12 col-xl-4">
                                  <div class="counter-card">
                                  <i class="bx bx-shopping-bag"></i>
                                      <div class="text">
                                          <h3><span class="counter">20</span>+</h3>
                                          <h5>Projects Done</h5>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-12 col-xl-4">
                                  <div class="counter-card">
                                  <i class="bx bx-happy"></i>
                                      <div class="text">
                                          <h3><span class="counter">20</span>+</h3>
                                          <h5>Happy Clients</h5>
                                      </div>
                                  </div>
                              </div>

                              <div class="col-md-12 col-xl-4">
                                  <div class="counter-card">
                                  <i class="bx bx-award"></i>
                                      <div class="text">
                                          <h3><span class="counter">5</span>+</h3>
                                          <h5>Recommendations</h5>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>
        <!-- End Counters -->

    </div>
</template>